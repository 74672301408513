// eslint-disable-next-line no-redeclare,no-unused-vars
function showInfo(str, type) {
  if (isArray(str)) str = str.join(', ');

  var pm = {
    position: 'top-center',
    color: 'success',
    autohide: true,
    delay: 3000,
    stacking: false,
  };
  var cont = $(
    '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true"></div>'
  ).appendTo(document.body);
  $('<div class="toast-body">' + str + '</div>').appendTo(cont);

  if (type == 'warning') pm.color = 'warning';
  else if (type == 'error') pm.color = 'danger';

  if (type == 'warning' || type == 'error') {
    pm.stacking = true;
    pm.delay = 10000;
    var contHeader = $('<div class="toast-header"></div>').prependTo(cont);
    var title = '';
    if (type == 'warning') title = 'Achtung';
    else if (type == 'error') title = 'Fehler';
    contHeader.append('<strong class="me-auto">' + title + '</strong>');
    contHeader.append(
      '<button class="btn-close" type="button" data-mdb-dismiss="toast" aria-label="Close"></button>'
    );
  }

  var toastObj = new mdb.Toast(cont[0], pm);
  toastObj.show();

  cont.on('hidden.mdb.toast', function () {
    cont.remove();
  });
}
